import React from 'react';
import LayoutWithContainer from '../components/layout-with-container';
import Map from '../components/map/map';
import '../components/contacts-page/contacts-page.scss';
import { Phone, Mail, Mark } from '../components/contacts/contacts';
import useWindowSize from '../../libs/useWindowSize';

const MAP_API_KEY = 'AIzaSyAq0sKagS7XCNF5p8vffa9zzR4U2_qXZ4U';
const MAP_API_URL = 'https://maps.googleapis.com/maps/api/js';
const mapUrlOptions = {
  key: MAP_API_KEY,
  v: '3.exp',
  libraries: ['geometry', 'drawing', 'places'],
};

const createParam = (prop, options) => {
  const value = options[prop];
  const keyString = prop.toString();
  const valueString = value.toString();

  return `${keyString}=${valueString}`;
};

const makeUrl = (options) => Object.keys(options).map((prop) => createParam(prop, options)).join('&');

const mapUrlParams = makeUrl(mapUrlOptions);
const mapUrl = `${MAP_API_URL}?${mapUrlParams}`;

const ContactsPage = () => {
  const [width] = useWindowSize();
  const mapH = width >= 992 ? 600 : 500;

  return (
    <LayoutWithContainer
      pageName="contacts-page"
      containerClass="contacts-page__map-container"
      mainClass="contacts-page__main"
    >
      <div className="contacts-page__container">
        <div className="contacts-page__inner">

          <div className="container">

            <h1 className="page__title page__title_h1 page__title_standard-page">Контакты</h1>

            <Phone className="contacts-page__contact" textClass="contacts-page__contact-text" />
            <Mail className="contacts-page__contact" textClass="contacts-page__contact-text" />
            <Mark
              className="contacts-page__contact"
              textClass="contacts-page__contact-text"
              text={(
                <span>
                  м. Войковская,
                  <br />
                  ул. Радиаторская, д. 25
                </span>
              )}
            />

          </div>

        </div>

      </div>
      <div className="contacts-page__map">
        <Map
          googleMapURL={mapUrl}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: `${mapH}px` }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      </div>
    </LayoutWithContainer>
  );
};

export default ContactsPage;
