import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import commonSiteData from '../../__mocks__/commonSiteData';

// todo alex add gatsby-plugin-react-svg
import markerURL from './map-mark.png';
import styles from './styles.json';
import './map.scss';

const position = { lat: commonSiteData.map.coords[0], lng: commonSiteData.map.coords[1] };

const Map = withScriptjs(withGoogleMap(() => (
  <GoogleMap
    defaultZoom={18}
    defaultCenter={position}
    defaultOptions={{ styles, disableDefaultUI: true }}
  >
    <Marker
      position={position}
      icon={{
        url: markerURL,
        size: new window.google.maps.Size(71, 89),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(35, 89),
      }}
    />
  </GoogleMap>
)));


export default Map;
